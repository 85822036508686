import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { oktaAuth } from './oktaAuth';

export default function useAuthUser() {
  const { authState } = useOktaAuth();
  const [user, setUser] = useState({groups: []});

  useEffect(() => {
    // Okta authenticates for us, we first need to wait for that to finish.
    if (authState && authState.isAuthenticated) {
      const user = {
        // The custom authentication server needs to be configured to return the groups claim.
        // See https://developer.okta.com/docs/guides/customize-tokens-groups-claim/main/#add-a-groups-claim-for-a-custom-authorization-server
        groups: authState.accessToken.claims.groups || [],
      };
      setUser(user);
      // Update user info if we don't have it yet.
      if (!user.name) {
        oktaAuth.token
          .getUserInfo()
          .then((info) => {
            setUser({
              ...user,
              ...info,
            });
          });
      }
    }
  }, [authState]);

  return [authState, user];
}
