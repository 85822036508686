import Header from '../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import Body from '../components/Body';
import { useImagesStore } from '../hooks/images';
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import { Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ScanButton } from '../components/ScanButton';
import NetworkStatus from '../components/NetworkStatus';

export default function ImageList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const images = useImagesStore((state) => state.images);
  const fetchImages = useImagesStore((state) => state.fetchImages);
  const statusRenderer = (params) => {
    switch (params.value) {
      case 'verified':
        return '✅ verified';
      case 'failed':
        return '❌ failed';
      case 'pending':
        return 'pending';
      case 'scanned':
        return 'scanned';
      case 'uploading':
        return '⬆';
      default:
        return '🤷';
    }
  };
  const columns = [
    { field: 'timeCreated', type: 'dateTime', headerName: 'Scan date', flex: 1 },
    { field: 'status', headerName: 'Status', renderCell: statusRenderer },
  ];

  const onRowClick = (params) => {
    history.push(`/images/${encodeURIComponent(params.row.id)}`);
  };

  const onClickScan = () => {
    history.push('/scan');
  };

  useEffect(() => {
    const updateList = () => {
      const t1 = setTimeout(() => setIsLoading(true), 1000);
      fetchImages()
        .then(() => {
          clearTimeout(t1);
          setErrMsg('');
          setIsLoading(false);
        })
        .catch((err) => {
          clearTimeout(t1);
          if (!window.navigator.onLine) {
            setErrMsg('You are offline. Uploads will continue when online.');
          } else {
            setErrMsg(err.message);
          }
          setIsLoading(false);
        });
    };
    updateList();
    const iv = setInterval(updateList, 10000);

    return () => {
      clearInterval(iv);
    };
  }, [fetchImages]);

  return (
    <>
      <Header title="Manage scans" />
      <Body>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
        { <NetworkStatus /> }
        <DataGrid
          minHeight="80vh"
          loading={isLoading}
          onRowClick={onRowClick}
          initialState={{
            sorting: {
              sortModel: [{ field: 'timeCreated', sort: 'desc' }],
            },
          }}
          rows={images}
          columns={columns}
        />
      </Body>
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }} component="footer">
        <ScanButton onClick={onClickScan} text="Scan Sticker" />
      </AppBar>
    </>
  );
}
