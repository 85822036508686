/**
 * @typedef {Object} ScanData
 * @property {Object} raw
 * @property {Object} parsed
 * @property {Object} parsed.amount
 * @property {string} parsed.amount.description
 * @property {Object} parsed.orderLine
 * @property {string} parsed.orderLine.description
 * @property {Object} parsed.partNumber
 * @property {string} parsed.partNumber.description
 * @property {Object} parsed.workOrder
 * @property {string} parsed.workOrder.description
 * @property {'YELLOW'|'WHITE'|'UNKNOWN'} [parsed.labelType]
 * @property {string} [parsed.topLabel]
 * @property {boolean} [parsed.coreReturn]
 * @property {[number, number, number]} [parsed.hsb]
 */

export class ImageMetadata {
  constructor({ id, publicLink, timeCreated, status, scanData, file }) {
    this.id = id;
    this.publicLink = publicLink;
    this.timeCreated = timeCreated;
    this.status = status;
    this.scanData = scanData || null;
    this.file = file || null;
  }
}
