import Header from '../components/Header';
import Body from '../components/Body';
import CameraFeed from '../components/CameraFeed';
import { useImagesStore } from '../hooks/images';
import { useState } from 'react';
import { Alert, LinearProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function ImageScan() {
  const history = useHistory();
  const [uploading, setUploading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const uploadFile = useImagesStore((state) => state.uploadFile);

  const onFile = (file) => {
    setUploading(true);
    uploadFile(file)
      .then((image) => {
        setErrMsg(null);
        setUploading(false);
        // Offline mode returns a temp image object that is used as a placeholder and not available yet in the backend.
        // So just redirect to the home page.
        const redirect = image ? `/images/${encodeURIComponent(image.id)}` : '/';
        history.push(redirect);
      })
      .catch((err) => {
        setErrMsg(err.message);
        setUploading(false);
      });
  };

  return (
    <>
      <Header title="Scan Sticker" backUrl="/" />
      <Body>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
        <CameraFeed onFile={onFile} onError={(errMsg) => setErrMsg(errMsg)} />
        {uploading && <LinearProgress />}
      </Body>
    </>
  );
}
