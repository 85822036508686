import Box from '@mui/material/Box';

export default function Body ({ children, height }) {

  return (
    <Box sx={{ height: height || '80vh', minHeight: '100%', marginTop:'100px', marginBottom:'100px'}}>
      {children}
    </Box>
  );
}
