import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import * as React from 'react';
import { Link } from 'react-router-dom';
import mainLogo from '../assets/images/icon-192x192.png';
import HelpIcon from '@mui/icons-material/Help';

const faqStyle = {
  color: '#ffe000',
  padding: '2px',
  marginTop: '7px'
};

export default function Header({ title, backUrl, noFaq }) {

  return (
    <>
      <AppBar component="nav" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <Toolbar>
          {backUrl && (<Button variant="text" component={Link} to={backUrl} startIcon={<ArrowBackIcon sx={{ color: '#fdba12' }} />}></Button>)}
          {!backUrl && <img src={mainLogo} alt="mainLogo" className="main-logo" /> }
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block' } }}
          >
            {title}
          </Typography>
          {!noFaq && <Link to="/faq" className="link" style={faqStyle}><HelpIcon color="white" /></Link>}
        </Toolbar>
      </AppBar>
    </>
  );
}
