import { Alert, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { ScanItemDto, ScanItemDtoYellow, validateScandata } from '../hooks/api';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * @param {ImageMetadata} annotated
 * @param {Function} handleSubmit
 * @param {Function} handleCancel
 * @returns {JSX.Element}
 * @constructor
 */
export default function ImageForm ({ annotated, handleSubmit, handleCancel }) {
  const useYellowForm = annotated.scanData.parsed.labelType === 'YELLOW';
  const initialValues = useMemo(() => createInitialValues(annotated), [annotated]);
  const [validationError, setValidationError] = useState(null);
  const checkFields = useCallback(async (values) => {
    const dto = useYellowForm
      ? new ScanItemDtoYellow(values)
      : new ScanItemDto(values);
    const allowedError = await validateScandata(dto);
    if (allowedError) {
      setValidationError(`Error ${allowedError.field}: ${allowedError.message}`);
    } else {
      setValidationError('');
    }
  }, [useYellowForm]);

  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      // The business wants to allow the user to submit the form even if there are errors.
      // We only check for empty fields here.
      const errors = {};
      Object.keys(initialValues).forEach((key) => {
        if (values[key] === '') {
          errors[key] = 'Required';
        }
      });
      await checkFields(values);
      return errors;
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    checkFields(initialValues).catch((err) => setValidationError(err.message));
  }, [initialValues, checkFields]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {useYellowForm && (
        <FormControl margin="normal" required fullWidth>
          <TextField
            fullWidth
            required
            id="topLabel"
            name="topLabel"
            label="Top label"
            value={formik.values.topLabel}
            onChange={formik.handleChange}
            error={formik.touched.topLabel && Boolean(formik.errors.topLabel)}
            helperText={formik.touched.topLabel && formik.errors.topLabel}
          />
        </FormControl>
      )}
      <FormControl margin="normal" required fullWidth>
        <TextField
          fullWidth
          required
          id="partNumber"
          name="partNumber"
          label="Part number"
          value={formik.values.partNumber}
          onChange={formik.handleChange}
          error={formik.touched.partNumber && Boolean(formik.errors.partNumber)}
          helperText={formik.touched.partNumber && formik.errors.partNumber}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <TextField
          fullWidth
          required
          id="orderLine"
          name="orderLine"
          label="Order / Line"
          value={formik.values.orderLine}
          onChange={formik.handleChange}
          error={formik.touched.orderLine && Boolean(formik.errors.orderLine)}
          helperText={formik.touched.orderLine && formik.errors.orderLine}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <TextField
          fullWidth
          required
          id="workOrder"
          name="workOrder"
          label="Work order"
          value={formik.values.workOrder}
          onChange={formik.handleChange}
          error={formik.touched.workOrder && Boolean(formik.errors.workOrder)}
          helperText={formik.touched.workOrder && formik.errors.workOrder}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <TextField
          fullWidth
          required
          type="number"
          id="amount"
          name="amount"
          label="Amount"
          InputProps={{
            inputProps: {
              max: 255, min: 1
            }
          }}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={formik.touched.amount && Boolean(formik.errors.amount)}
          helperText={formik.touched.amount && formik.errors.amount}
        />
      </FormControl>
      {useYellowForm && (
        <FormControl margin="normal" required fullWidth>
          <FormControlLabel required label="Is Core Return?" control={
            <Checkbox
              id="coreReturn"
              name="coreReturn"
              checked={formik.values.coreReturn}
              onChange={formik.handleChange}
            />
          }
          />
        </FormControl>
      )}

      {validationError && <Alert severity="error"><p>{validationError}</p><p>Are you sure you want to send this in? Correct the field or create a new photo.</p></Alert>}

      <Button type="button" variant="outlined" onClick={() => handleCancel()}>Cancel</Button>
      <Button type="submit" variant="contained" sx={{ float: 'right' }}>Save and verify</Button>
    </form>
  );
}

/**
 * @param {ImageMetadata} annotated
 * @returns {{workOrder: string, amount: string, orderLine: string, partNumber: string}|{amount: string, coreReturn: boolean, topLabel: string, orderLine: string}}
 */
export function createInitialValues(annotated) {
  if (annotated.scanData.parsed.labelType === 'YELLOW') {
    return {
      topLabel: annotated.scanData.parsed.topLabel,
      coreReturn: annotated.scanData.parsed.coreReturn,
      partNumber: annotated.scanData.parsed.partNumber.description,
      orderLine: annotated.scanData.parsed.orderLine.description,
      workOrder: annotated.scanData.parsed.workOrder.description,
      amount: annotated.scanData.parsed.amount.description,
    };
  }

  return {
    partNumber: annotated.scanData.parsed.partNumber.description,
    orderLine: annotated.scanData.parsed.orderLine.description,
    workOrder: annotated.scanData.parsed.workOrder.description,
    amount: annotated.scanData.parsed.amount.description,
  };
}
