import { Switch, Route, useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import Layout from './components/Layout';
import { theme } from './setting';
import { ThemeProvider } from '@mui/material/styles';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuth } from './auth/oktaAuth';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { UserProvider } from './contexts/UserProvider';
import ImageList from './views/ImageList';
import ImageDetail from './views/ImageDetail';
import ImageScan from './views/ImageScan';
import Faq from './views/Faq';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
    mutations: {
      retry: 2,
    }
  }
});

export default function App() {
  const history = useHistory();
  const restoreOriginalUri = useCallback(async () => {
    history.replace(toRelativeUrl('', window.location.origin));
  }, [history]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <Layout>
              <Switch>
                <Route path='/login/callback' component={LoginCallback} />
                <Route path='/health' component={Health} />
                <SecureRoute path='/' exact component={ImageList} />
                <SecureRoute path='/images/:id' exact component={ImageDetail} />
                <SecureRoute path='/scan' exact component={ImageScan} />
                <SecureRoute path='/faq' exact component={Faq} />
              </Switch>
            </Layout>
          </QueryClientProvider>
        </UserProvider>
      </ThemeProvider>
    </Security>
  );
}

function Health() {
  return 'OK';
}
