import { oktaAuth } from '../auth/oktaAuth';
import { API_REPORTS, API_ROOT } from '../Constants';

/**
 * @param {Object<string, string>} [options] `Fetch` options Object
 *
 * @returns {Object<string, string>} Resolves to options
 */
function addOktaBearerToFetchOptions(options) {
  const allOptions = { ...options };
  const accessToken = oktaAuth.getAccessToken();
  const headerValue = `Bearer ${accessToken}`;
  const headerName = 'Authorization';

  // Other headers might already exist
  if (allOptions.headers) {
    // headers can be `Headers`
    if (allOptions.headers.set) {
      allOptions.headers.set(headerName, headerValue);
    } else {
      // or simple JSON
      allOptions.headers[headerName] = headerValue;
    }
  } else {
    // Just add simple JSON instead
    allOptions.headers = {
      [headerName]: headerValue,
    };
  }
  // Original object is modified, but returning to be polite
  return allOptions;
}

/**
 * GET data from the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 * @param {object} options // Options to send with the request.
 */
export async function getDataFromApi(endpoint, options = {}) {
  const authOptions = addOktaBearerToFetchOptions(options);

  return fetch(`${API_ROOT}${endpoint}`, {
    method: 'GET',
    ...authOptions,
  })
    .then(async (res) => {
      const isJson = res.headers.get('content-type').includes('application/json');
      const isPdf = res.headers.get('content-type').includes('application/pdf');
      if (isPdf && res.ok) {
        return res;
      }
      if (isJson && res.ok) {
        return await res.json();
      }
      if (!res.ok) {
        const body = isJson ? await res.clone().json() : { message: res.statusText };
        throw Error(body.message);
      }
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export const defaultPostHeaders = {
  headers: {
    'Content-Type': 'application/json',
  },
};

/**
 * Post data from the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 * @param {object} options // Options to send with the request.
 * @param {string|FormData} body // Body to send with the request.
 * @param {string} method // Request method POST or PATCH.
 */
export async function pushDataToApi(endpoint, options = {}, body = '', method = 'POST') {
  const authOptions = addOktaBearerToFetchOptions(options);

  return fetch(`${API_ROOT}${endpoint}`, {
    method: method,
    ...authOptions,
    body,
  })
    .then(async (res) => {
      if (!res.ok) {
        const isJson = res.headers.get('content-type').includes('application/json');
        const body = isJson ? await res.clone().json() : { message: res.statusText };
        throw Error(body.message);
      }
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export class ScanItemDto {
  constructor({partNumber, orderLine, workOrder, amount, topLabel, coreReturn}) {
    this.partNumber = partNumber;
    this.orderLine = orderLine;
    this.workOrder = workOrder;
    this.amount = parseInt(amount, 10);
    this.topLabel = topLabel;
    this.coreReturn = coreReturn;
  }
}

export class ScanItemDtoYellow {
  constructor({ topLabel, partNumber, orderLine, workOrder, amount, coreReturn }) {
    this.topLabel = topLabel;
    this.coreReturn = coreReturn;
    this.partNumber = partNumber;
    this.orderLine = orderLine;
    this.workOrder = workOrder;
    this.amount = parseInt(amount, 10);
  }
}

export class FieldError extends Error {
  constructor (message, field) {
    super(message);
    this.field = field;
  }
}

/**
 * Post validation data. Null if valid, Error object if invalid.
 *
 * @param {ScanItemDto|ScanItemDtoYellow} body
 * @returns {Promise<FieldError|null>}
 */
export async function validateScandata(body) {
  const authOptions = addOktaBearerToFetchOptions({
    headers: {
      'Content-Type': 'application/json',
    }
  });

  const url = body instanceof ScanItemDtoYellow
    ? `${API_REPORTS}/validate-yellow`
    : `${API_REPORTS}/validate`;

  return fetch(url, {
    method: 'POST',
    ...authOptions,
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        return null;
      }

      if (res.status > 400) {
        return new FieldError(await res.text(), 'unknown');
      }

      if (res.status === 400) {
        const body = await res.json();
        let message = typeof body.message === 'string' ? String(body.message) : 'Invalid input';
        if (Array.isArray(body.message)) {
          message = body.message.join(', ');
        }

        return new FieldError(message, body.field || '');
      }

      return new FieldError(await res.text(), '');
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
