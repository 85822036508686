import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';

export const YellowButton = styled(Button)({
  height: '60px',
  backgroundColor: '#fdba12',
  color: '#000000',
  fontSize: '16px',
  borderRadius: '0px',
});

export function ScanButton({ onClick, text }) {
  return <YellowButton variant="contained" color="gradient" startIcon={<CameraEnhanceIcon />} onClick={onClick}>{text}</YellowButton>;
}
