import { useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ImageForm from './ImageForm';
import { useImagesStore } from '../hooks/images';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '600px',
  maxHeight: '90vh',
  overflow: 'scroll',
  padding: '20px',
  boxSizing: 'border-box',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const editAction = 'Edit';
const deleteAction = 'Delete';
const verifyAction = 'Verify';
const actionButtonStyle = {
  flexGrow: 1,
  padding: '20px 10px 40px 10px'
};

export default function AnnotatedImage ({ annotated }) {
  const { id } = annotated;
  const history = useHistory();
  const [errMsg, setErrMsg] = useState('');

  // Modal state.
  const [open, setOpen] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalAction, setModalAction] = useState('');
  const deleteImage = useImagesStore((state) => state.deleteImage);
  const saveImage = useImagesStore((state) => state.saveImage);

  // Canvas state.
  const wrapper = useRef(null);
  const canvasRef = useRef(null);
  useEffect(() => {
    if (canvasRef.current) {
      drawImageCanvas(annotated, canvasRef);
    }
  }, [wrapper, annotated, canvasRef]);

  const fields = getFields(annotated);

  const onDelete = () => {
    deleteImage(id)
      .then(() => history.push('/'))
      .catch((err) => setErrMsg(err.message));
  };
  const onEdit = (values) => {
    saveImage(id, values)
      .then(() => history.push('/'))
      .catch((err) => setErrMsg(err.message));
  };
  const onVerify = () => {
    saveImage(id)
      .then(() => history.push('/'))
      .catch((err) => setErrMsg(err.message));
  };

  // Modal functions.
  const openModal = (action) => {
    let text = '';
    if (action === deleteAction) {
      text = 'Are you sure you want to delete this scan?';
    }
    if (action === editAction) {
      text = 'Edit the metadata of the scan by entering new values in the form below.';
    }
    if (action === verifyAction) {
      text = 'If the scanned or entered data is accurate, click VERIFY to mark this scan as verified. This action cannot be undone.';
    }
    if (text === '') {
      return;
    }
    setErrMsg('');
    setModalText(text);
    setModalAction(action);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setCancelled(true);
  };
  const agreeModal = () => {
    switch (modalAction) {
      case deleteAction:
        onDelete();
        break;
      case verifyAction:
        onVerify();
        break;
      default:
        break;
    }
  };

  // On page load open the form if status is scanned and cancelled not clicked.
  useEffect(() => {
    if (annotated.status === 'scanned' && cancelled === false) {
      openModal(editAction);
    }
  });

  return (
    <>
      <h1>Scan {annotated.timeCreated}</h1>
      <div className="scan-image-wrapper" ref={wrapper}>
        <canvas ref={canvasRef} />
      </div>
      { annotated.status === 'uploading' && window.navigator.onLine && <Alert severity="info">This scan is being uploaded.</Alert> }
      { annotated.status === 'pending' && <Alert severity="info">This image is in the queue for scanning.</Alert> }
      <div className="scan-metadata">
        { ['scanned', 'verified'].includes(annotated.status) && fields.map((field) => (
          <div className="scan-metadata-item" key={field.label}>
            <span className="scan-metadata-item-label">{field.label}: </span>
            <span className="scan-metadata-item-value">{field.value}</span>
          </div>
        ))}
      </div>
      <div className="scan-actions">
        {annotated.status === 'scanned' &&
          <>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, display: 'flex', flexDirection: 'row' }} component="footer">
              <Button variant="contained" startIcon={<DeleteForeverIcon />} sx={actionButtonStyle} onClick={() => openModal(deleteAction)}>Delete</Button>
              <Button variant="contained" startIcon={<EditIcon />} sx={actionButtonStyle} onClick={() => openModal(editAction)}>Edit</Button>
              <Button variant="contained" startIcon={<CheckCircleIcon />} sx={actionButtonStyle} onClick={() => openModal(verifyAction)}>Verify</Button>
            </AppBar>
            <Modal
              open={open}
              onClose={closeModal}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={modalStyle}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
                <h3 id="parent-modal-title">{modalText}</h3>
                { modalAction === editAction && <ImageForm annotated={annotated} handleSubmit={onEdit} handleCancel={closeModal} /> }
                { [verifyAction, deleteAction].includes(modalAction) && <Button variant="contained" color="secondary" onClick={agreeModal} sx={{ float: 'right' }}>{modalAction}</Button> }
              </Box>
            </Modal>
          </>
        }
      </div>
    </>
  );
}

function drawImageCanvas(annotated, ref) {
  const canvas = ref.current;
  const ctx = canvas.getContext('2d');
  const img = new Image();
  // Load the image and draw to canvas.
  img.onload = () => {
    const dpi = window.devicePixelRatio;
    const width = canvas.offsetWidth * dpi;
    const height = width * 3 / 4;
    canvas.width = width;
    canvas.height = height;
    const ratio = Math.min(canvas.width/img.width, canvas.height/img.height);
    // Let's get the new width and height based on the scale factor
    const newWidth = img.width * ratio;
    const newHeight = img.height * ratio;
    // get the top left position of the image
    // in order to center the image within the canvas
    const x = (canvas.width/2) - (newWidth/2);
    const y = (canvas.height/2) - (newHeight/2);
    ctx.drawImage(img, x, y, newWidth, newHeight);
    ctx.imageSmoothingEnabled = false;
    if (!annotated.scanData) {
      return;
    }
    drawBoxes(annotated, ctx, width / img.width);
  };
  if (annotated.publicLink) {
    img.src = annotated.publicLink;
  }
  if (annotated.file) {
    const reader = new FileReader();
    reader.addEventListener('load', () => img.src = reader.result, false);
    reader.readAsDataURL(annotated.file);
  }
}

function drawBoxes(annotated, ctx, ratio) {
  const draw = (vertices, color) => {
    const [topLeft, _, bottomRight] = vertices;
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 3;
    ctx.rect(
      topLeft.x * ratio,
      topLeft.y * ratio,
      (bottomRight.x - topLeft.x) * ratio,
      (bottomRight.y - topLeft.y) * ratio,
    );
    ctx.stroke();
  };
  annotated.scanData.raw.responses.forEach((response) => {
    response.textAnnotations?.forEach((annotation) => draw(annotation.boundingPoly.vertices, 'yellow'));
    response.fullTextAnnotation?.pages[0].blocks.forEach((block) => block.paragraphs.forEach((paragraph) => draw(paragraph.boundingBox.vertices, 'green')));
  });
  Object.values(annotated.scanData.parsed).forEach((annotation) => {
    if (annotation.boundingPoly?.vertices?.length !== 4) {
      return;
    }
    draw(annotation.boundingPoly?.vertices, 'red');
  });
}

/**
 * @param {ImageMetadata} annotated
 * @returns {[{label: string, value},{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: *}]}
 */
function getFields(annotated) {
  if (annotated.scanData?.parsed.labelType === 'YELLOW') {
    return [
      { label: 'Status', value: annotated.status },
      { label: 'Top label', value: annotated.scanData?.parsed.topLabel },
      { label: 'Part number', value: annotated.scanData?.parsed.partNumber.description },
      { label: 'Order / Line', value: annotated.scanData?.parsed.orderLine.description },
      { label: 'Work order', value: annotated.scanData?.parsed.workOrder.description },
      { label: 'Amount', value: annotated.scanData?.parsed.amount.description },
      { label: 'Core return', value: annotated.scanData?.parsed.coreReturn ? 'Yes' : 'No' },
    ];
  }

  return [
    { label: 'Status', value: annotated.status },
    { label: 'Part number', value: annotated.scanData?.parsed.partNumber.description },
    { label: 'Order / Line', value: annotated.scanData?.parsed.orderLine.description },
    { label: 'Work order', value: annotated.scanData?.parsed.workOrder.description },
    { label: 'Amount', value: annotated.scanData?.parsed.amount.description },
  ];
}
