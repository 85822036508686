import Header from '../components/Header';
import Body from '../components/Body';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Faq() {
  const qas = [
    {
      question: 'Do I need internet?',
      answer: 'Yes, you need internet to upload images and scan data to the cloud',
    },
    {
      question: 'How do I create good images?',
      answer: 'Make sure you have good lighting and the image is in focus.',
    },
    {
      question: 'Can the app scan images rotated or skewed?',
      answer: 'Unfortunately, no. The app can only scan images that are straight and not rotated.',
    },
    {
      question: 'Are images stored on my device?',
      answer: 'No, your images are uploaded to the cloud'
    },
    {
      question: 'My image is still saying pending, what do I do?',
      answer: 'Check your internet connection first and create a new image if you are still having issues.'
    },
    {
      question: "Why can't I delete my image?",
      answer: 'Only images that have NOT been verified can be deleted',
    },
    {
      question: 'Some text is not scanned, what do I do?',
      answer: 'Try to take a better image and make sure the text is in focus and not blurry. Or you can edit the text manually.'
    },
    {
      question: 'Do I need to verify images?',
      answer: 'No, you do not need to verify images. However, this will ensure we know you have checked the image and the text is correct.'
    }
  ];

  return (
    <>
      <Header title="FAQ" backUrl="/" noFaq={true} />
      <Body>
        { qas.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )) }

      </Body>
    </>
  );
}
