import { create } from 'zustand';
import { getDataFromApi, pushDataToApi } from './api';
import { ImageMetadata } from './dto';

const rootPath = '/api/v1/images';

export const useImagesStore = create((set, get) => ({
  images: [],
  uploadFile: async (file) => {
    const date = new Date();
    const tempId = String(date.getTime());
    // Add to state to indicate uploading.
    const image = new ImageMetadata({
      id: tempId,
      publicLink: '',
      timeCreated: date.toISOString(),
      status: 'uploading',
      // Only used in offline mode when the image is not yet available in the backend.
      file: file,
    });
    set((state) => ({ images: [...state.images, image] }));
    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await pushDataToApi(rootPath, {}, formData, 'POST');
      if (!res.ok) {
        throw new Error(`Error uploading file: ${res.statusText}`);
      }
      const data = await res.json();
      const newImage = new ImageMetadata(data);
      set((state) => ({ images: [...state.images.filter((image) => image.id !== tempId), newImage] }));

      return newImage;
    } catch (e) {
      // Only rethrow if it is an API error and not network related.
      if (e.message.includes('Error uploading file')) {
        throw e;
      }
    }
    // Offline mode.
    return null;
  },
  fetchImages: async () => {
    const getData = async (pageToken = '') => {
      let url = rootPath;
      if (pageToken) {
        url += `?pageToken=${pageToken}`;
      }
      const data = await getDataFromApi(url);
      if (data.nextPageToken) {
        return [...data.files, ...await getData(data.nextPageToken)];
      }
      return data.files;
    };
    const data = await getData();
    const images = data.map((image) => new ImageMetadata(image));
    set({ images: images });
  },
  fetchImage: async (id) => {
    try {
      const data = await getDataFromApi(`${rootPath}/${encodeURIComponent(id)}`);
      const image = new ImageMetadata(data);

      set((state) => ({ images: [...state.images.filter((image) => image.id !== id), image] }));

      return image;
    } catch (e) {
      if (e.message.includes('Error uploading file')) {
        throw e;
      }
      // Return the image from state if it exists.
      return get().images.filter((image) => image.id === id).pop();
    }
  },
  deleteImage: async (id) => {
    const res = await getDataFromApi(`${rootPath}/${encodeURIComponent(id)}`, { method: 'DELETE' });
    if (!res.ok) {
      throw new Error(await res.text());
    }
    set((state) => ({ images: state.images.filter((image) => image.id !== id) }));
  },
  saveImage: async (id, values = null) => {
    const image = get().images.find((item) => item.id === id);
    if (!image) {
      throw new Error(`Image ${id} not found.`);
    }

    const body = {};
    // Update metadata values from form.
    if (values) {
      Object.keys(values).forEach((key) => {
        switch (key) {
          case 'partNumber':
          case 'orderLine':
          case 'workOrder':
          case 'amount':
            image.scanData.parsed[key].description = values[key];
            break;

          case 'coreReturn':
          case 'topLabel':
            image.scanData.parsed[key] = values[key];
            break;

          default:
            // Do nothing.
        }
        body[key] = values[key];
      });
    }

    // Passes validation, store in backend and frontend.
    image.status = 'verified';
    body.status = 'verified';

    const res = await getDataFromApi(`${rootPath}/${encodeURIComponent(id)}`, { method: 'PATCH', body: JSON.stringify(body) });
    if (!res.ok) {
      throw new Error(await res.text());
    }

    set((state) => ({ images: state.images.map((image) => (image.id === id ? { ...image } : image)) }));
  }
}));
