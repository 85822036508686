import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';

export default function NetworkStatus() {
  const [online, setOnline] = useState(navigator.onLine || true);

  useEffect(() => {
    function changeStatus() {
      setOnline(navigator.onLine);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);

    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  return (
    online
      ? <></>
      : <Alert severity="error">You are offline. Please check your internet connection</Alert>
  );
}
