import { useEffect, useRef, useState } from 'react';
import { ScanButton } from './ScanButton';
import Box from '@mui/material/Box';
import { getOrientation } from 'o9n';

export default function CameraFeed({ onFile, onError }) {
  const [showVideo, setShowVideo] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    // Resize canvas to match width of window and 4/3 aspect ratio.
    const canvas = canvasRef.current;

    const orientation = getOrientation().type.startsWith('portrait')
      ? 'portrait'
      : 'landscape';

    // Enable camera.
    const constraint = {
      video: {
        facingMode: 'environment',
        // This is needed for handheld devices to ensure proper image ratio when snapping a photo.
        aspectRatio: orientation === 'portrait' ? 3/4 : 4/3,
      },
      audio: false
    };
    navigator.mediaDevices.getUserMedia(constraint)
      .then((mediaStream) => {
        if (!video.srcObject) {
          video.srcObject = mediaStream;
          canvas.width = video.offsetWidth;
          canvas.height = video.offsetHeight;
        }
      })
      .catch((err) => onError(err.message));

    return () => {
      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => track.stop());
        video.srcObject = null;
      }
    };
  }, [onError]);

  const onTakePhoto = async () => {
    setShowVideo(false);
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const ratio = videoRef.current.offsetHeight / videoRef.current.offsetWidth;
    const newWidth = 1920;
    const newHeight = newWidth * ratio;
    const imageBitmap = await createImageBitmap(videoRef.current, { resizeWidth: newWidth, resizeHeight: newHeight, resizeQuality: 'high' });
    // OffscreenCanvas is not supported in Safari yet.
    const cv = document.createElement('canvas');
    cv.width = imageBitmap.width;
    cv.height = imageBitmap.height;
    cv.getContext('2d').drawImage(imageBitmap, 0, 0);
    cv.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to convert canvas to blob');
      }
      const file = new File([blob], 'image.png', { type: 'image/png' });
      onFile(file);
    });
  };

  return (
    <div className="camera-feed">
      <div className="camera-feed__viewer">
        <video ref={videoRef} width="100%" height="257" autoPlay playsInline style={{ display: showVideo ? 'block' : 'hidden' }}/>
        <canvas ref={canvasRef} />
      </div>
      <Box sx={{ textAlign: 'center' }}>
        {showVideo && <ScanButton onClick={onTakePhoto} text="click" />}
      </Box>
    </div>
  );
}
