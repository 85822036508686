import { useParams } from 'react-router-dom';
import { useImagesStore } from '../hooks/images';
import { useEffect, useState } from 'react';
import Header from '../components/Header';
import Body from '../components/Body';
import AnnotatedImage from '../components/AnnotatedImage';
import Alert from '@mui/material/Alert';
import NetworkStatus from '../components/NetworkStatus';

export default function ImageDetail () {
  const { id } = useParams();
  const [annotated, setAnnotated] = useState(null);
  const [errMsg, setErrMsg] = useState('');
  const fetchImage = useImagesStore((state) => state.fetchImage);

  useEffect(() => {
    // This id is an url encoded string with slashes for React router, we need to decode it before using it.
    fetchImage(decodeURIComponent(id))
      .then((image) => setAnnotated(image))
      .catch((err) => setErrMsg(err.message));
  }, [fetchImage, id]);

  return (
    <>
      <Header title="Scan status" backUrl="/" />
      <Body height="100%">
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
        { <NetworkStatus /> }
        {annotated && <AnnotatedImage annotated={annotated} />}
      </Body>
    </>
  );
}
